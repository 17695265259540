import { createIntl, createIntlCache, FormattedMessage, IntlShape } from 'react-intl';
import { enLanguage, shortEnLanguage, shortTrLanguage, trLanguage } from '../common/constants';
import { store } from '../stores';
import translations from '../translations';

export const translate = (id: string, values: any = undefined): any => {
    return <FormattedMessage id={id} values={values} />
}

export const translateString = (intl: IntlShape, id: string, values: any = undefined) => {
    return intl.formatMessage({ id }, values)
}

export const getShortLanguage = (lang: string) => {
    switch (lang) {
        case enLanguage:
            return shortEnLanguage;
        default:
            return shortTrLanguage;
    }
}

export const getLanguage = () => {
    switch (store.getState()?.settings.language) {
        case shortEnLanguage:
            return enLanguage;
        default:
            return trLanguage;
    }
}

export const getTranslatedIntl = (id: string, values: any = undefined) => {
    const locale = store.getState()?.settings?.language;
    const messages = translations[locale]
    const cache = createIntlCache();
    const intl = createIntl({ locale, messages }, cache)
    
    return intl.formatMessage({id: id}, values);
}
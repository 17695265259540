import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { cloudAIInstance } from "./httpBase";

const handleConfig = (requestConfig: AxiosRequestConfig | undefined, header: AxiosRequestHeaders | undefined) => {
    const config: AxiosRequestConfig = {
        ...requestConfig,
        headers: { ...header }
    }
    return config
}

export const getReqKeys = (endpoint: string, data: any, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined, keys: string[] = ['id']) => {
    return cloudAIInstance.get(endpoint + `?${keys.map((key, index) => key + '=' + data[index]).join('&')}`, handleConfig(requestConfig, header));
};

export const getReq = (endpoint: string, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined) => {
    return cloudAIInstance.get(endpoint, handleConfig(requestConfig, header));
};

export const getReqKey = (endpoint: string, data: any, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined, key: string = 'id') => {
    return cloudAIInstance.get(endpoint + `?${key}=` + data, handleConfig(requestConfig, header));
};

export const postReq = (endpoint: string, data: any, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined) => {
    return cloudAIInstance.post(endpoint, data, handleConfig(requestConfig, header));
};

export const putReq = (endpoint: string, data: any, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined) => {
    return cloudAIInstance.put(endpoint, data, handleConfig(requestConfig, header));
};

export const removeReqId = (endpoint: string, data: any, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined, key: string = 'ids') => {
    return cloudAIInstance.delete(endpoint + `?${key}=` + data, handleConfig(requestConfig, header));
};

export const removeReqIds = (endpoint: string, data: any, header: AxiosRequestHeaders | undefined = undefined, requestConfig: AxiosRequestConfig|undefined = undefined) => {
    const param = data && typeof(data) === 'object' ? data.join('&ids=') : data
    return cloudAIInstance.delete(endpoint + '?ids=' + param, handleConfig(requestConfig, header));
};
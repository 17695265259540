import * as Keys from "./keys";

let tr: Record<string, string> = {};

tr[Keys.AccountSecurity] = "Account Security";
tr[Keys.Account] = "Account";
tr[Keys.AccuracyRatio] = "Accuracy Ratio";
tr[Keys.Accuracy] = "Accuracy";
tr[Keys.ActivationMailSent] = "Aktivasyon e-postası gönderildi";
tr[Keys.ActiveApiKeyListed] = "Active Api Key Listed";
tr[Keys.AddClass] = "Add a Class";
tr[Keys.AddFileToUseLiveInterface] = "Add a file to use live interface";
tr[Keys.AddLogo] = "Logo Ekle";
tr[Keys.AddToSavedAddress] = "Add To Saved Address";
tr[Keys.AddressIsSaved] = "Address is saved";
tr[Keys.Address] = "Address";
tr[Keys.AiDepartmentApiKeyDeleteConfirmMessage] = "Are you sure to delete the Key AI Department Api Key If you deleted this Api key users using this key will no longer be able to work with this Api key";
tr[Keys.AiDepartmentApiKeyRegenerateConfirmMessage] = "Are you sure to regenerate the Key AI Department Api Key";
tr[Keys.AiDepartmentApiKeyRegenerateWarningMessage] = "If you regenerate this Api key users using this key will no longer be able to work with this Api key and they'll need to work with the renewed key";
tr[Keys.AiDepartmentApiKey] = "AI Department Api Key";
tr[Keys.AllKeys] = "All Keys";
tr[Keys.AllServicesAllowed] = "All services allowed";
tr[Keys.AllYouNeedIsAKey] = "All you need is a key";
tr[Keys.All] = "Tümü";
tr[Keys.AllowedServices] = "Allowed Services";
tr[Keys.AlreadyAMember] = "Zaten bir hesabın var mı?";
tr[Keys.Amount] = "Tutar";
tr[Keys.AngleCalculateDescription] = "Helps users determine angles between lines or objects, facilitating geometric calculations and design tasks."
tr[Keys.AngleCalculate] = "Angle Calculate";
tr[Keys.AngleRotateDescription] = "Enables users to rotate objects or images by a specified angle, providing flexibility in design and presentation.";
tr[Keys.AngleRotate] = "Angle Rotate";
tr[Keys.ApiKeyCreated] = "Api Key Created";
tr[Keys.ApiKeyListed] = "Api Key Listed";
tr[Keys.ApiKeyName] = "Api Key Name";
tr[Keys.ApiKey] = "Api Key";
tr[Keys.ApiKeys] = "Api Keys";
tr[Keys.ApiReferenceDescription] = "How to use your Api in your environment";
tr[Keys.ApiReference] = "Api Reference";
tr[Keys.ApiResponse] = "Api Response";
tr[Keys.ApiServicesDocumentDescription] = "Document function is about document related processes.Merge and Split or manipulate pdf pages using this api set.";
tr[Keys.ApiServicesExtractDescription] = "Extraction is a process of using AI and machine learning algorithms to identify and extract specific data from a document, image or other input source.";
tr[Keys.ApiServicesMetadataDescription] = "The Metadata Extraction and Removal Api is a versatile tool designed to efficiently extract and eliminate metadata from both image and Pdf files.";
tr[Keys.ApiServicesOCRDescription] = "OCR software analyzes the image of the text and recognizes each character, then converts them into machine-encoded text that can be edited, searched, and processed by computers.";
tr[Keys.ApiServicesRotationDescription] = "Rotation correction can be done manually or automatically using algorithms that detect the angle of rotation needed to align the document.";
tr[Keys.ApiServicesSignatureDescription] = "The Signature Processing Api is a robust solution that leverages deep learning and AI technologies to perform various tasks related to signatures.";
tr[Keys.ApiServices] = "Api Services";
tr[Keys.Api] = "Api";
tr[Keys.ApproveFile] = "Approve File";
tr[Keys.ApproveWithExpressConsent] = "Açık rızamla onaylıyorum.";
tr[Keys.AreYouSure] = "Are you sure";
tr[Keys.ArkApiTermsMessage] = "I agree to the <u>Ark Api Terms</u> and accept the <u>Automatic Renewal Terms</u>.";
tr[Keys.ArkApiTermsErrorMessage] = "You must agree to the Ark Api Terms and accept the Automatic Renewal Terms.";
tr[Keys.AuthenticationAddApiKey] = "Add Api key to your header";
tr[Keys.Authentication] = "Authentication";
tr[Keys.Back] = "Geri";
tr[Keys.BaseModel] = "Base Model";
tr[Keys.BaselineAccuracy] = "Baseline Accuracy";
tr[Keys.BestAccuracy] = "Best Accuracy";
tr[Keys.BillingDetails] = "Billing Details";
tr[Keys.Billing] = "Billing";
tr[Keys.BoxesCalculateDescription] = "Assists users in calculating the dimensions and positions of boxes or containers.";
tr[Keys.BoxesCalculate] = "Boxes Calculate";
tr[Keys.BoxesDrawDescription] = "Allows users to visually create and customize boxes or containers, aiding in graphical representation.";
tr[Keys.BoxesDraw] = "Boxes Draw";
tr[Keys.BuiltPlan] = "Built Plan";
tr[Keys.BuyNow] = "Buy Now";
tr[Keys.Csv] = ".CSV";
tr[Keys.Cancel] = "İptal et";
tr[Keys.CardDeclinedDueToTransactionProcessing] = "Card declined due to issues with transaction processing";
tr[Keys.CardNumber] = "Card Number";
tr[Keys.CardholderName] = "Cardholder Name";
tr[Keys.ChangeEmail] = "Change Email";
tr[Keys.ChangeMyPassword] = "Şifremi değiştir";
tr[Keys.ChangePassword] = "Şifre Değiştir";
tr[Keys.CheckInternetConnection] = "Lütfen internet bağlantınızı kontrol ediniz.";
tr[Keys.Checkout] = "Checkout";
tr[Keys.City] = "City";
tr[Keys.ClassificationDescription] = "Assign different classes or categories to documents  helping with storage, management";
tr[Keys.Classification] = "Classification";
tr[Keys.CleanImageDescription] = "Assists users in calculating the dimensions and positions of boxes or containers.";
tr[Keys.CleanImage] = "Clean Image";
tr[Keys.CleanPdfDescription] = "Allows users to visually create and customize boxes or containers, aiding in graphical representation.";
tr[Keys.CleanPdf] = "Clean Pdf";
tr[Keys.ClearFilter] = "Filtreleri Temizle";
tr[Keys.Clear] = "Temizle";
tr[Keys.ClickBelowToTrainNewModel] = "Click below to train a new model";
tr[Keys.ClickBelowTrainNewModel] = "Click below to train a new model";
tr[Keys.ClientId] = "Client ID";
tr[Keys.CompanyName] = "Company Name";
tr[Keys.CompareDescription] = "Helps users compare two or more documents or images, highlighting differences and similarities.";
tr[Keys.ComparePlans] = "Compare Plans";
tr[Keys.Compare] = "Compare";
tr[Keys.ConfidenceLevelTooltipMessage1] = "The extractions level of confidence is indicated here.";
tr[Keys.ConfidenceLevelTooltipMessage2] = "The field needs a person to verify the value if the icon is yellow or red.";
tr[Keys.ConfidenceLevel] = "Confidence Level";
tr[Keys.ConfirmFile] = "Confirm File";
tr[Keys.ConfirmPassword] = "Şifrenizi onaylayın";
tr[Keys.ConfirmPayment] = "Confirm Payment";
tr[Keys.Confirm] = "Onayla";
tr[Keys.ContactUs] = "Contact us";
tr[Keys.ContactWithUs] = "Contact with us";
tr[Keys.Continue] = "Devam et";
tr[Keys.ContractApproval] = "Kontrat Onay";
tr[Keys.ContractKvkkApprovalMessage] = "* Sisteme giriş yapabilmek için Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Ve Rıza Metnini onaylamanız gerekmektedir.";
tr[Keys.Copied] = "Copied";
tr[Keys.CopyApiKey] = "Copy Api Key";
tr[Keys.CorporateInvoice] = "Corporate Invoice";
tr[Keys.CouldNotLoadCaptchaMessage] = "Captcha Yüklenemedi. Lütfen Sayfayı Yenileyiniz.";
tr[Keys.CountDocument] = "{count} Document";
tr[Keys.CountPages] = " {count} sayfa";
tr[Keys.CountServices] = "{count} Services";
tr[Keys.Country] = "Country";
tr[Keys.CreateApiKey] = "Create Api Key";
tr[Keys.CreateDate] = "Create Date";
tr[Keys.CreateNewKey] = "Create a New Key";
tr[Keys.CreateNewModel] = "Create a New Model";
tr[Keys.CreateNewPasswordMessage] = "Create a new password with at least 8 characters long.";
tr[Keys.CreateNewPassword] = "Yeni şifre oluştur";
tr[Keys.CreateOne] = "Oluşturun";
tr[Keys.CreateYourAccount] = "Hesap Oluştur";
tr[Keys.CreateYourFirstKey] = "Create Your First Key";
tr[Keys.Create] = "Create";
tr[Keys.CreditSpendingsPerApiKey] = "Credit Spendings Per Api Key";
tr[Keys.CreditSpendingsPerService] = "Credit Spendings Per Service";
tr[Keys.CreditSpendingsWithName] = "Credit Spendings of {name}";
tr[Keys.CreditSpendings] = "Credit Spendings";
tr[Keys.CreditSystem] = "Credit System";
tr[Keys.CreditUsagePerPage] = "Credit usage per page";
tr[Keys.CreditUsage] = "Credit Usage";
tr[Keys.CreditsAndBillingPage] = "Credits & Billing Page";
tr[Keys.Credits] = "Credits";
tr[Keys.CropDescription] = "Allows users to trim or remove unwanted areas from images or documents, optimizing visual content.";
tr[Keys.Crop] = "Crop";
tr[Keys.Curl] = "Curl";
tr[Keys.CurrencyType] = "Para Birimi";
tr[Keys.CurrentBillingCycle] = "Mevcut Fatura Dönemi";
tr[Keys.CurrentPasswordPlaceholder] = "Mevcut şifreyi girin";
tr[Keys.CurrentPassword] = "Mevcut Şifre";
tr[Keys.Daily] = "Günlük";
tr[Keys.DateRange] = "Tarih Aralığı";
tr[Keys.Days] = "Days";
tr[Keys.DeleteAll] = "Hepsini Sil";
tr[Keys.DeleteApiKey] = "Delete Api Key";
tr[Keys.DeleteChangesConfirmMessage] = "Seçtiklerinizi silmek istediğinizden emin misiniz?";
tr[Keys.DeleteKey] = "Delete Key";
tr[Keys.DeleteLogo] = "Logoyu sil";
tr[Keys.Delete] = "Delete";
tr[Keys.Description] = "Description";
tr[Keys.DoYouHaveMoreQuestions] = "Do you have more questions?";
tr[Keys.DocumentTrainDescription] = "Document Train Description";
tr[Keys.DocumentTrain] = "Document Train";
tr[Keys.Document] = "Document";
tr[Keys.DocumentationFunctionsOfArkApiDocumentP1] = "This function is dedicated to handling document-related processes with precision and efficiency. By utilizing this Api set, users have the capability to merge and split Pdf pages according to their specific needs. For instance, you can effortlessly combine multiple Pdf documents into a single file, making it easier to manage and share. Contrarily, if you have a large Pdf document that needs to be divided into smaller, more manageable sections, this function allows you to do so seamlessly. Beyond merging and splitting, the Api set also provides robust tools for manipulating Pdf pages in various ways, ensuring that your document management tasks are streamlined and efficient. Whether for business, educational, or personal use, this function enhances your ability to organize and customize your Pdf documents to meet your exact requirements.";
tr[Keys.DocumentationFunctionsOfArkApiExtractListHeader] = "Invoice:";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem10] = "discount amount";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem11] = "total amount";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem1] = "time";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem2] = "date";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem3] = "payment method";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem4] = "tax ration";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem5] = "tax amount";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem6] = "tax office";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem7] = "merchant name";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem8] = "invoice number";
tr[Keys.DocumentationFunctionsOfArkApiExtractListItem9] = "discount ratio";
tr[Keys.DocumentationFunctionsOfArkApiExtractP1] = "Extraction of labelled information or image, or a part from the input refers to the process of using artificial intelligence (AI) and machine learning algorithms to identify and extract specific data from a document, image or other input source.";
tr[Keys.DocumentationFunctionsOfArkApiExtractP2] = "This process involves labeling the areas of interest within the input, such as certain words or fields within a form, and extract these specific labels with a high degree of accuracy. This enables the AI system to extract relevant information quickly and accurately from large amounts of data, saving time and improving efficiency in tasks such as data entry, document processing, and information retrieval.";
tr[Keys.DocumentationFunctionsOfArkApiExtractP3] = "User can benefit either our pre-defined models or tailor made models. Currently, we have two pre-defined models and the entities they possess, which are the keys they will output, are as follows:";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem1Content] = "The Api is equipped to intelligently extract a variety of metadata from images and Pdf files. This includes information such as author details, creation dates, geolocation, camera specifications (for images), and document properties (for Pdfs).";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem1Label] = "Metadata Extraction:";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem2Content] = "The Api is not limited to specific file formats, allowing seamless handling of both image files (common formats like JPEG, PNG, etc.) and Pdf files. This adaptability ensures compatibility with a diverse range of documents.";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem2Label] = "Format-Agnostic:";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem3Content] = "In addition to extraction, the Api is designed to remove metadata from the processed files. This includes sensitive information, comments, annotations, and any other metadata that users may want to exclude for privacy or security reasons.";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem3Label] = "Metadata Removal:";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem4Content] = "The Api ensures that sensitive metadata information is securely removed, addressing privacy concerns and complying with data protection regulations.";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem4Label] = "Secure Handling of Sensitive Information:";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem5Content] = ":Cleaned files are provided in standardized output formats, facilitating easy integration with various applications and systems while ensuring that no sensitive metadata is retained.";
tr[Keys.DocumentationFunctionsOfArkApiMetadataListItem5Label] = "Output Formats:";
tr[Keys.DocumentationFunctionsOfArkApiMetadataP1] = "The Metadata Extraction and Removal Api is a versatile tool designed to efficiently extract and eliminate metadata from both image and Pdf files. This Api combines extraction capabilities with a focus on privacy and security through metadata removal:";
tr[Keys.DocumentationFunctionsOfArkApiOcrP1] = "OCR stands for Optical Character Recognition. It is a technology that enables the conversion of scanned or photographed images of text into editable and searchable digital text. OCR software analyzes the image of the text and recognizes each character, then converts them into machine-encoded text that can be edited, searched, and processed by computers. OCR is commonly used to digitize printed documents, such as books, magazines, and newspapers, as well as to extract data from scanned business cards, receipts, invoices, and other types of documents. Handwritten OCR will be implemented soon.";
tr[Keys.DocumentationFunctionsOfArkApiOcr] = "OCR (Optical Character Recognition)";
tr[Keys.DocumentationFunctionsOfArkApiRotationP1] = "Before performing OCR on a scanned document or extraction processes in general, it is important to ensure that the document is correctly oriented. This can be done through our Rotation service.";
tr[Keys.DocumentationFunctionsOfArkApiRotationP2] = "Rotation correction can be done manually or automatically using algorithms that detect the angle of rotation needed to align the document. Automatic rotation correction is especially useful when dealing with large volumes of documents that may have been scanned at different orientations.";
tr[Keys.DocumentationFunctionsOfArkApiRotationP3] = "In short: Detect the text in images. Find the angle and make the adjustments according to angle in the image such as rotating.";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem1Content] = "The Api can accurately detect signatures within a given document or image, utilizing advanced pattern recognition and neural network models. It can identify and locate signatures even in complex or cluttered backgrounds, ensuring reliable detection.";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem1Label] = "Signature Detection:";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem2Content] = "Once a signature is detected, the Api can automatically crop the signature area, providing a focused and isolated representation of the signature for further analysis or display.";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem2Label] = "Signature Cropping:";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem3Content] = "The Api employs sophisticated algorithms to compare two signatures and assess their similarity or dissimilarity.";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem3Label] = "Signature Comparison:";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem4] = "It provides a confidence score or similarity metric, aiding in the verification and validation of signatures for authentication purposes.";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem5Content] = "The Api is powered by state-of-the-art deep learning models trained on diverse signature datasets to ensure high accuracy and adaptability to various signature styles and variations.";
tr[Keys.DocumentationFunctionsOfArkApiSignatureListItem5Label] = "Deep Learning Models:";
tr[Keys.DocumentationFunctionsOfArkApiSignatureP1] = "The Signature Processing Api is a robust solution that leverages deep learning and AI technologies to perform various tasks related to signatures. This versatile Api offers the following key functionalities:";
tr[Keys.DocumentationFunctionsOfArkApiSignatureP2] = "With these capabilities, the Signature Processing Api provides a comprehensive solution for organizations and developers seeking to integrate advanced signature-related functionalities into their applications, document management systems, or authentication processes.";
tr[Keys.DocumentationFunctionsOfArkApi] = "Functions of ArkApi";
tr[Keys.DocumentationGuideDescription] = "You can learn details about ArkApi and find answers for your questions.";
tr[Keys.DocumentationHowDoesCreditSystemWorkP1] = "The credit system offered by ArkApi is a simple payment method specifically designed for the platform's functionalities. Credit system  is calculated based on the number of pages made to the Api. After purchasing credits, the corresponding amount of credits will be automatically deducted from your account for each Api request made.";
tr[Keys.DocumentationHowDoesCreditSystemWorkP2] = "Visit our purchase page to get more information and review our packages.";
tr[Keys.DocumentationHowDoesCreditSystemWork] = "How does Credit System Work?";
tr[Keys.DocumentationWhatDoesArkApiOfferP1] = "ArkApi is a powerful Api tool that provides developers and end-users with a wide range of functionalities to simplify their workflows. With ArkApi, developers can easily integrate OCR and data extraction capabilities into their applications, enabling them to extract data from a variety of input types, including Pdf, images, and text. In addition to OCR and data extraction, ArkApi also offers document rotation, splitting, and merging capabilities, making it a comprehensive solution for managing documents of all types. Whether you're working with large, complex documents or small, simple ones, ArkApi's powerful AI and machine learning algorithms ensure that you can easily manipulate and manage them to suit your needs.";
tr[Keys.DocumentationWhatDoesArkApiOfferP2] = "By offering these functionalities, ArkApi eliminates the need for manual document processing, reducing errors and increasing efficiency. This allows developers and end-users to focus on more important tasks, such as developing new features or analyzing data, without worrying about time-consuming and error-prone document management tasks.";
tr[Keys.DocumentationWhatDoesArkApiOfferP3] = "With ArkApi, developers and end-users can enjoy a streamlined workflow, improved accuracy, and increased productivity, all powered by the latest AI and machine learning technologies. Whether you're a seasoned developer or just starting out, ArkApi is the perfect solution for simplifying your document management tasks and improving your workflow.";
tr[Keys.DocumentationWhatDoesArkApiOffer] = "What does ArkApi Offer?";
tr[Keys.DocumentationWhatIsArkApiForListHeader] = "ArkApi is especially useful for those who: ";
tr[Keys.DocumentationWhatIsArkApiForListItem1] = "Need to retrieve and analyze large amounts of data quickly and accurately.";
tr[Keys.DocumentationWhatIsArkApiForListItem2] = "Want to build custom integrations that interact with different data sources.";
tr[Keys.DocumentationWhatIsArkApiForListItem3] = "Are developing data-driven applications that require real-time access to data. ";
tr[Keys.DocumentationWhatIsArkApiForListItem4] = "Need to extract high-quality data.";
tr[Keys.DocumentationWhatIsArkApiForP1] = "ArkApi is a powerful tool designed to simplify application development by providing a suite of Apis for managing and analyzing data. Whether you're building web or mobile applications, conducting data analysis, or creating custom integrations, ArkApi is the perfect solution for developers who want to streamline their workflows and increase efficiency.";
tr[Keys.DocumentationWhatIsArkApiForP2] = "With ArkApi, developers can eliminate the need for manual data entry and reduce errors during data extraction. This frees up time and resources to focus on more essential tasks, such as developing new features and improving user experience. Whether you're a seasoned developer or just getting started, ArkApi is the perfect solution for managing and analyzing data efficiently and effectively.";
tr[Keys.DocumentationWhatIsArkApiFor] = "What is ArkApi for?";
tr[Keys.DocumentationWhatIsArkApiP1] = "Introducing ArkApi, a cutting-edge OCR software and Api platform designed to streamline document processing and enhance automation workflows. Leveraging the power of computer vision and machine learning, ArkApi provides developers with a standardized document processing layer that can recognize and extract key information with ease. Our pre-trained data models for common documents, such as invoices, receipts, and passports, make it simple for developers to integrate with our platform.";
tr[Keys.DocumentationWhatIsArkApiP2] = "At ArkApi, our mission is to provide our customers with real-time, human-level accuracy in data extraction from both paper and digital documents. With our Api-first approach, we aim to empower developers to create innovative solutions and unlock new business opportunities by harnessing the power of data recognition technology. Join the growing community of businesses already benefiting from the automation and efficiency provided by ArkApi’s Api platform.";
tr[Keys.DocumentationWhatIsArkApi] = "What is ArkApi?";
tr[Keys.Documentation] = "Documentation";
tr[Keys.DocumentsUsed] = "Documents used";
tr[Keys.Done] = "Done";
tr[Keys.DontHaveAnAccount] = "Henüz bir hesabınız yok mu?";
tr[Keys.DotNetCSharp] = ".Net(C#)";
tr[Keys.DragAndDropFilesHere] = "Drag and drop files here";
tr[Keys.Or] = "or";
tr[Keys.Supported] = "Supported";
tr[Keys.ClickHereToUpload] = "Click Here to Upload";
tr[Keys.FileSizeMax] = "File size should be maximum";
tr[Keys.EArchive] = "E-Archive";
tr[Keys.EInvoice] = "E-Invoice";
tr[Keys.Edit] = "Düzenle";
tr[Keys.EmailAddress] = "Email Address";
tr[Keys.EmailConfirmedSuccessfully] = "E-posta adresi başarıyla aktive edildi.";
tr[Keys.Email] = "E-posta";
tr[Keys.EnFlag] = "English";
tr[Keys.EnLang] = "EN";
tr[Keys.En] = "EN";
tr[Keys.EnterYourEmailAddress] = "E-posta adresinizi giriniz";
tr[Keys.EnumActive] = "Active";
tr[Keys.EnumClassification] = "Classification";
tr[Keys.EnumCreated] = "Created";
tr[Keys.EnumCurrentBillingCycle] = "Mevcut Fatura Dönemi";
tr[Keys.EnumDaily] = "Günlük";
tr[Keys.EnumDateRange] = "Tarih Aralığı";
tr[Keys.EnumDate] = "Date";
tr[Keys.EnumDocumentPdfCompress] = "Pdf Compress";
tr[Keys.EnumDocumentPdfDecrypt] = "Pdf Decrypt";
tr[Keys.EnumDocumentPdfEncrypt] = "Pdf Encrypt";
tr[Keys.EnumDocumentPdfExtractImage] = "Pdf Extract Image";
tr[Keys.EnumDocumentPdfMerge] = "Pdf Merge";
tr[Keys.EnumDocumentPdfSplit] = "Pdf Split";
tr[Keys.EnumDocument] = "Document";
tr[Keys.EnumExpired] = "Expired";
tr[Keys.EnumExtractImage] = "Image";
tr[Keys.EnumExtractPdf] = "Pdf";
tr[Keys.EnumExtractText] = "Text";
tr[Keys.EnumExtractTiff] = "Tiff";
tr[Keys.EnumExtract] = "Extract";
tr[Keys.EnumHigh] = "High";
tr[Keys.EnumIdentity] = "Identity";
tr[Keys.EnumInvoice] = "Invoice";
tr[Keys.EnumLastBillingCycle] = "Son Fatura Dönemi";
tr[Keys.EnumLow] = "Low";
tr[Keys.EnumMetadataCleanImage] = "Clean Image";
tr[Keys.EnumMetadataCleanPdf] = "Clean Pdf";
tr[Keys.EnumMetadataExtractImage] = "Extract Image";
tr[Keys.EnumMetadataExtractPdf] = "Extract Pdf";
tr[Keys.EnumMetadata] = "Metadata";
tr[Keys.EnumModelInProgress] = "In Progress";
tr[Keys.EnumMonthly] = "Aylık";
tr[Keys.EnumNer] = "Named Entity Recognition (NER)";
tr[Keys.EnumNumeric] = "Numeric";
tr[Keys.EnumOcrImage] = "Image";
tr[Keys.EnumOcrPdf] = "Pdf";
tr[Keys.EnumOcrTiff] = "Tiff";
tr[Keys.EnumOcr] = "Ocr";
tr[Keys.EnumReadyForUse] = "Ready";
tr[Keys.EnumRevoked] = "Revoked";
tr[Keys.EnumRisky] = "Risky";
tr[Keys.EnumRotationAngleCalculate] = "Angle Calculate";
tr[Keys.EnumRotationAngleRotate] = "Angle Rotate";
tr[Keys.EnumRotationBoxesCalculate] = "Boxes Calculate";
tr[Keys.EnumRotationBoxesDraw] = "Boxes Draw";
tr[Keys.EnumRotation] = "Rotation";
tr[Keys.EnumSignatureCompare] = "Compare";
tr[Keys.EnumSignatureCrop] = "Crop";
tr[Keys.EnumSignatureExtractCoordinate] = "Extract Coordinate";
tr[Keys.EnumSignature] = "Signature";
tr[Keys.EnumText] = "Text";
tr[Keys.EnumTime] = "Time";
tr[Keys.EnumTrainFromScratch] = "Train From Scratch";
tr[Keys.EnumTraining] = "Training";
tr[Keys.EnumWeekly] = "Haftalık";
tr[Keys.ExpiryDate] = "Expiry Date";
tr[Keys.ExploreOurApiServices] = "Explore our Api Services";
tr[Keys.Export] = "İndir";
tr[Keys.ExtractCoordinateDescription] = "Enables users to extract and retrieve coordinate data from documents or images.";
tr[Keys.ExtractCoordinate] = "Extract Coordinate";
tr[Keys.ExtractImageDescription] = "Helps users determine angles between lines or objects, facilitating geometric calculations and design tasks.";
tr[Keys.ExtractImageInfo] = "Extract Image Info";
tr[Keys.ExtractImage] = "Extract Image";
tr[Keys.ExtractPdfDescription] = "Enables users to rotate objects or images by a specified angle, providing flexibility in design and presentation.";
tr[Keys.ExtractPdfInfo] = "Extract Pdf Info";
tr[Keys.ExtractPdf] = "Extract Pdf";
tr[Keys.ExtractTextInfo] = "Extract Text Info";
tr[Keys.ExtractTiffInfo] = "Extract Tiff Info";
tr[Keys.Extract] = "Extract";
tr[Keys.ExtractedInformation] = "Extracted Information";
tr[Keys.FieldMax] = "{fieldname} alanı maksimum {max} karakter olmalıdır.";
tr[Keys.FieldMin] = "{fieldname} alanı minimum {min} karakter olmalıdır.";
tr[Keys.FieldNotValid] = " {fieldname} geçerli değil";
tr[Keys.FieldRequired] = "Lütfen alanı giriniz";
tr[Keys.FileTypes] = "File types";
tr[Keys.Filters] = "Filtreler";
tr[Keys.ForgotPassword] = "Parolanızı mı unuttunuz?";
tr[Keys.FormDataFormFile] = "The formFile can be either a file object";
tr[Keys.FormData] = "Form data";
tr[Keys.FormDesc2] = "Oluşturun.";
tr[Keys.FormDesc] = "Henüz bir hesabınız yok mu?";
tr[Keys.FreePage] = "Free page";
tr[Keys.GeneralErrorJson] = "General Error JSON response example";
tr[Keys.GetInTouch] = "Get in touch";
tr[Keys.GoToHomePage] = "Go to homepage";
tr[Keys.GoToPurchasing] = "Go to Purchasing";
tr[Keys.GoToSignInPage] = "Giriş sayfasına git";
tr[Keys.GotConfusedExpertsHereToHelp] = "Got confused? ARKAPI's experts are here to help!";
tr[Keys.Guide] = "Guide";
tr[Keys.HideApiKey] = "Hide Api Key";
tr[Keys.HighAccuracy] = "High Accuracy";
tr[Keys.Home] = "Home";
tr[Keys.HttpRequest] = "HTTP Request";
tr[Keys.IHaveReadAndAccept] = "Okudum ve kabul ediyorum";
tr[Keys.IHaveReadAndApprovePreliminaryInformation] = "I have read and approve the Preliminary Information";
tr[Keys.IdentityNumber] = "TC Number";
tr[Keys.IfYouNeedAnyHelpContactUs] = "If you need any help please contact with us";
tr[Keys.ImageInfoDescription] = "Provides detailed information about images, including dimensions, format, color depth, and relevant metadata.";
tr[Keys.ImageInfo] = "Image Info";
tr[Keys.Inactive] = "Pasif";
tr[Keys.Individual] = "Individual";
tr[Keys.InsufficientCreditJSON] = "Insufficient Credit JSON response example";
tr[Keys.Invalid] = "Geçersiz";
tr[Keys.InvalidEmailAddress] = "Geçersiz Email Adressi";
tr[Keys.InvalidPasswordFormat] = "Şifre en az bir büyük harf ve bir küçük harf içermelidir";
tr[Keys.Invoice] = "Invoice";
tr[Keys.ItFlag] = "Italian";
tr[Keys.ItLang] = "IT";
tr[Keys.ItsEmptyInHere] = "Ohh... it's empty in here";
tr[Keys.JavaScript] = "JavaScript";
tr[Keys.JoinTheArkApiNow] = "ARKApi ile Bize Katılın!";
tr[Keys.Json] = ".JSON";
tr[Keys.LargeDocumentOcr] = "Large Document Ocr?";
tr[Keys.LastBillingCycle] = "Son Fatura Dönemi";
tr[Keys.LeavePage] = "Leave Page";
tr[Keys.LimitationsDescription1] = "These limits apply for your current plan.";
tr[Keys.LimitationsDescription2] = "If you need to higher them up,";
tr[Keys.Limitations] = "Limitations";
tr[Keys.LinkSent] = "Link gönderildi!";
tr[Keys.LiveInterface] = "Live Interface";
tr[Keys.Login] = "Giriş Yap";
tr[Keys.LogoDeleteSuccessfully] = "Logo Başarıyla Silindi";
tr[Keys.LogoUploadSuccessfully] = "Logo Başarıyla Düzenlendi";
tr[Keys.ManageYourAccount] = "Manage Your Account";
tr[Keys.MaxFileSizeWarning] = "Sending unsupported files to your endpoints will result in a 400 error.";
tr[Keys.MaxFileSize] = "Maximum file size";
tr[Keys.MembershipAgreement] = "Üyelik Sözleşmesi";
tr[Keys.MetadataCleanImage] = "Metadata Clean Image";
tr[Keys.MetadataCleanPdf] = "Metadata Clean Pdf";
tr[Keys.MetadataExtractImage] = "Metadata Extract Image";
tr[Keys.MetadataExtractPdf] = "Metadata Extract Pdf";
tr[Keys.Metadata] = "Metadata";
tr[Keys.ModelCreatedSuccessfully] = "Model başarıyla oluşturuldu.";
tr[Keys.ModelName] = "Model Name";
tr[Keys.ModelTrainingUploadErrorMessage] = "Please annotate and confirm at least 20 documents to train your first model.";
tr[Keys.ModelTrainingUploadMessage] = "You must annotate at least 20 documents to train your model, and more annotations will significantly improve the accuracy.";
tr[Keys.ModelTrainingUploadWarningMessage] = "You must annotate and confirm at least 20 documents to train your first model.";
tr[Keys.ModelTraining] = "Model Training";
tr[Keys.ModelType] = "Model Type";
tr[Keys.ModerateAccuracy] = "Moderate Accuracy";
tr[Keys.Month] = "month";
tr[Keys.Monthly] = "Aylık";
tr[Keys.MoreInformation] = "Daha Fazla Bilgi";
tr[Keys.MsExcel] = "MS Excel";
tr[Keys.MustBeGreaterThanField] = "Girilen değer {fieldname} alanından büyük olmamalıdır";
tr[Keys.MyCurrentPlan] = "My Current Plan";
tr[Keys.MyProfile] = "My Profile";
tr[Keys.NameSurname] = "Adı Soyadı";
tr[Keys.NameYourClass] = "Name Your Class";
tr[Keys.NameYourModel] = "Name Your Model";
tr[Keys.NewEmailAddress] = "New Email Address";
tr[Keys.NewField] = "Yeni {name}";
tr[Keys.NewPasswordAgainPlaceholder] = "Yeni şifreyi tekrar girin";
tr[Keys.NewPasswordAgain] = "Yeni Şifre Tekrar";
tr[Keys.NewPasswordPlaceholder] = "Yeni şifreyi girin";
tr[Keys.NewPassword] = "Yeni Şifre";
tr[Keys.Next] = "Sonraki";
tr[Keys.NoApiKeyMeetsCriteria] = "There is no Api key that meets these criteria";
tr[Keys.NoCancel] = "Hayır, iptal";
tr[Keys.NoDataMessage] = "Henüz herhangi bir {noDataModuleName} oluşturmadınız. Yeni bir {noDataModuleName} oluşturmak için + tuşuna basın";
tr[Keys.NoData] = "Data yok...";
tr[Keys.NoResultDescription] = "Bir kez daha denemeye ne dersiniz?";
tr[Keys.NoResult] = "Sonuç yok...";
tr[Keys.NoServiceSelected] = "No service selected";
tr[Keys.No] = "Hayır";
tr[Keys.NotApproveWithExpressConsent] = "Açık rızamla onaylamıyorum.";
tr[Keys.NumberOfAnnotatedDocuments] = "Number of Annotated Documents";
tr[Keys.NumberOfEntities] = "Number of Entities";
tr[Keys.NumberOfPage] = "Number of Page";
tr[Keys.OcrImageInfo] = "OCR Image Info";
tr[Keys.OcrPdfInfo] = "OCR Pdf Info";
tr[Keys.OcrTiffInfo] = "OCR Tiff Info";
tr[Keys.Ocr] = "Ocr";
tr[Keys.OhhItsEmptyInHere] = "Ohh... it's empty in here";
tr[Keys.Ok] = "Tamam";
tr[Keys.OneLastStep] = "Son Adım!";
tr[Keys.Operator] = "Operatör";
tr[Keys.OrganizationKeyCanCustomizeMessage] = "Organization each key can be customized for services";
tr[Keys.OurPricing] = "Our Pricing";
tr[Keys.PageReadyToCreateYourFirstApiKey] = "This page is ready for you to create your first Api Key";
tr[Keys.Page] = "page";
tr[Keys.EmailChangedSuccessfully] = "Email başarıyla değiştirildi";
tr[Keys.PasswordChangedSuccessfully] = "Şifre başarıyla değiştirildi";
tr[Keys.PasswordFormatMessage] = "Şifre en az bir büyük harf ve bir küçük harf içermelidir";
tr[Keys.PasswordResetMailSent] = "Şifre sıfırlama e-postası gönderildi";
tr[Keys.PasswordResetSuccessfully] = "Şifre başarıyla sıfırlandı";
tr[Keys.Password] = "Şifre";
tr[Keys.PasswordsNotMatch] = "Şifreler eşleşmiyor";
tr[Keys.PayAsYouGo] = "Pay as You Go";
tr[Keys.PaymentDetails] = "Payment Details";
tr[Keys.PaymentSuccessful] = "Payment Successful";
tr[Keys.Payment] = "Payment";
tr[Keys.PdfCompressDescription] = "Reduce the file size of Pdf documents, making them easier to share and store without compromising quality.";
tr[Keys.PdfCompress] = "Pdf Compress";
tr[Keys.PdfDecryptDescription] = "Remove encryption from Pdf documents, allowing authorized individuals to access.";
tr[Keys.PdfDecrypt] = "Pdf Decrypt";
tr[Keys.PdfEncryptDescription] = "Secure Pdf documents by adding encryption, ensuring that only authorized individuals.";
tr[Keys.PdfEncrypt] = "Pdf Encrypt";
tr[Keys.PdfExtractImageDescription] = "Extract images embedded within Pdf documents, providing a way to access and use images separately.";
tr[Keys.PdfExtractImage] = "Pdf Extract Image";
tr[Keys.PdfInfoDescription] = "Offers essential details about Pdf documents, such as metadata, properties, and page count.";
tr[Keys.PdfInfoExtractFeedbackMessage] = "Please provide us feedback approving whether your data matches the results.";
tr[Keys.PdfInfoNoResponseMessage] = "Select an Api Key, a modal,an OCR option and use service  to extract informations by type.";
tr[Keys.PdfInfoServiceInfoMessage] = "Your credits won’t be spent until you  use the service.";
tr[Keys.PdfInfo] = "Pdf Info";
tr[Keys.PdfMergeDescription] = "Combine multiple Pdf files into a single document, facilitating streamlined document organization.";
tr[Keys.PdfMerge] = "Pdf Merge";
tr[Keys.PdfSplitDescription] = "Divide a single Pdf document into multiple separate files,manage specific sections or pages of a document.";
tr[Keys.PdfSplit] = "Pdf Split";
tr[Keys.PhoneNumber] = "Phone Number";
tr[Keys.Plan] = "Plan";
tr[Keys.PleaseFieldName] = "Please {field}";
tr[Keys.PleaseFillOutExpiryDate] = "Please fill out Expiry Date";
tr[Keys.Post] = "Post";
tr[Keys.PreliminaryInformationForm] = "Preliminary Information Form";
tr[Keys.Processing] = "Processing";
tr[Keys.PurchaseMoreCredits] = "Purchase more credits";
tr[Keys.Purchasing] = "Purchasing";
tr[Keys.Python] = "Python";
tr[Keys.QueryParameterDescription] = "Add query parameter to api url";
tr[Keys.QueryParameter] = "Query Parameter";
tr[Keys.Recommended] = "Önerilen";
tr[Keys.RecordCancelledSuccessfully] = "Kayıt başarıyla iptal edildi.";
tr[Keys.RecordDeletedSuccessfully] = "Kayıt başarıyla silindi.";
tr[Keys.RecordSavedSuccessfully] = "Kayıt başarıyla oluşturuldu.";
tr[Keys.RecordUpdatedSuccessfully] = "Kayıt başarıyla güncellendi.";
tr[Keys.ReenterYourArkApiPassword] = "Reenter your ArkApi password to continue";
tr[Keys.RefreshKey] = "Refresh Key";
tr[Keys.RegenerateKeyMessageTitle] = "Are you sure to regenerate the Key “{name}”?";
tr[Keys.RegenerateKeyMessage] = "If you regenerate this Api key, users using this key will no longer be able to work with this Api key and they’ll need to work with the renewed key.";
tr[Keys.RegenerateKey] = "Regenerate Key";
tr[Keys.Regenerate] = "Regenerate";
tr[Keys.RegisterCompleteMessage] = "Kayıt işlemi gerçekleştirildi. Üyeliğinizin aktive olması için gelen aktivasyon mailindeki linke tıklayınız.";
tr[Keys.Remaining] = "Remaining";
tr[Keys.RequestBodyDescription] = "Request Body";
tr[Keys.RequestBody] = "Body";
tr[Keys.Required] = "Lütfen {fieldname} alanını giriniz";
tr[Keys.ResendEmail] = "Resend Email";
tr[Keys.ResetLinkSentToMailAddress] = "Şifre sıfırlama e-postası gönderildi.";
tr[Keys.ResetMyPassword] = "Şifremi sıfırla";
tr[Keys.ResetPasswordInvalidToken] = "Kullanılmış kod. Lütfen tekrar şifre yenileme talebinde bulununuz veya en son gönderilen e-posta içerisindeki link üzerinden işlem yapınız.";
tr[Keys.ResetPassword] = "Şifre Sıfırla";
tr[Keys.ResetYourPassword] = "Şifremi sıfırla";
tr[Keys.ResponseSchemeDescription] = "This section describes the extracted features in your Api response.";
tr[Keys.ResponseScheme] = "Response Scheme";
tr[Keys.ResponseStructure] = "Response structure";
tr[Keys.ReturnToSite] = "Return to Site";
tr[Keys.ReviewAgain] = "Review Again";
tr[Keys.RevokeApiKeyMessageTitle] = "Are you sure to revoke the Key “{name}”?";
tr[Keys.RevokeApiKeyMessage] = "If you revoke this Api key, users using this key will no longer be able to work with this Api key.";
tr[Keys.RevokeApiKey] = "Revoke Api Key";
tr[Keys.RotationAngleCalculate] = "Rotation Angle Calculate";
tr[Keys.RotationAngleRotate] = "Rotation Angle Rotate";
tr[Keys.RotationBoxesCalculate] = "Rotation Boxes Calculate";
tr[Keys.RotationBoxesDraw] = "Rotation Boxes Draw";
tr[Keys.Rotation] = "Rotation";
tr[Keys.SampleCode] = "Sample Code";
tr[Keys.SaveAndGoToModelPage] = "Save & Go to Model Page";
tr[Keys.SaveAndSubmit] = "Kaydet & Gönder";
tr[Keys.SaveLogo] = "Logoyu kaydet";
tr[Keys.Save] = "Kaydet";
tr[Keys.SavedAddress] = "Saved Address";
tr[Keys.SavedAddresses] = "Saved Adresses";
tr[Keys.SearchApiKeyActive] = "Search Api Key Active";
tr[Keys.SearchApiKey] = "Search Api Key";
tr[Keys.Search] = "Ara";
tr[Keys.SecurityCode] = "Security Code";
tr[Keys.SeeSpendingsInDetail] = "See Spendings In Detail";
tr[Keys.SelectAddress] = "Select Address";
tr[Keys.SelectAll] = "Tümünü seç";
tr[Keys.SelectAnServiceFromBelowToSeeUsageTime] = "Select a service from below to see its usage by time";
tr[Keys.SelectAtLeastOneService] = "You should select at least one service";
tr[Keys.SelectModel] = "Select Model";
tr[Keys.SelectServiceFromBelowToSeeStatisticsForThatService] = "Select a service from below to see statistics for that service";
tr[Keys.SelectYourBaseModel] = "Select Your Base Model";
tr[Keys.SelectYourModelType] = "Select Your Model Type";
tr[Keys.SelectYourPlan] = "Select Your Plan";
tr[Keys.SelectedCount] = "{count} Seçili";
tr[Keys.SendMail] = "Mail gönder";
tr[Keys.ServiceApiKeyNotCreatedYetMessage] = "You have not created any Api keys yet. To use the services, you must first create an Api key.";
tr[Keys.ServiceCategories] = "Service Categories";
tr[Keys.ServiceCategories] = "Service Categories";
tr[Keys.ServicesAllowed] = "services allowed";
tr[Keys.Services] = "{count} Services";
tr[Keys.SetupFields] = "Set up Fields";
tr[Keys.SetupClasses] = "Set up Classes";
tr[Keys.ShortDocumentOcr] = "Short Document Ocr?";
tr[Keys.ShowApiKey] = "Show Api Key";
tr[Keys.SignInDescription] = "Ark Api";
tr[Keys.SignInWithApiKey] = "Api Anahtarı ile Giriş Yapın";
tr[Keys.SignIn] = "Giriş";
tr[Keys.SignOut] = "Çıkış";
tr[Keys.SignatureCompare] = "Signature Compare";
tr[Keys.SignatureCrop] = "Signature Crop";
tr[Keys.SignatureExtractCoordinate] = "Signature Extract Coordinate";
tr[Keys.Signature] = "Signature";
tr[Keys.SomethingWentWrongConfirmation] = "Aktivasyon işleminde bir hata oluştu";
tr[Keys.SortBy] = "Sırala:";
tr[Keys.SpendingsByCategories] = "Spendings by Categories";
tr[Keys.SpendingsByTime] = "Spendings by Time";
tr[Keys.Statistics] = "Statistics";
tr[Keys.StatusChangedSuccesfully] = "Statü başarılı bir şekilde güncellendi";
tr[Keys.Status] = "Statü";
tr[Keys.Submit] = "Onaya Gönder";
tr[Keys.SuccessFulJSON] = "Successful JSON response example";
tr[Keys.SupportedDocuments] = "Supported documents";
tr[Keys.TaxNumber] = "Tax Number";
tr[Keys.TaxOffice] = "Tax Office";
tr[Keys.TestYourModel] = "Test Your Model";
tr[Keys.TextInfoDescription] = "Offers detailed information about text content, including text-related metadata ";
tr[Keys.TextInfo] = "Text Info";
tr[Keys.ThisCardNumberIsNotValid] = "This card number is not valid";
tr[Keys.TiffInfoDescription] = "Provides detailed information about TIFF, including relevant metadata, aiding in image processing.";
tr[Keys.TiffInfo] = "Tiff Info";
tr[Keys.ToPurchaseMoreCredits] = "to purchase more credits";
tr[Keys.TotalCountPages] = " toplam ({count} sayfa)";
tr[Keys.TotalSpendings] = "Total Spendings";
tr[Keys.Total] = "total";
tr[Keys.TrFlag] = "Türkçe";
tr[Keys.TrLang] = "TR";
tr[Keys.Tr] = "TR";
tr[Keys.TrainModel] = "Train Model";
tr[Keys.TrainNewModel] = "Train a New Model";
tr[Keys.TryAgain] = "Try again";
tr[Keys.Undo] = "Geri Al";
tr[Keys.UpdateEmailMessage] = "When you update your email address well send you an email asking you to confirm the change";
tr[Keys.Update] = "Güncelle";
tr[Keys.Upload] = "Yükle";
tr[Keys.Url] = "URL";
tr[Keys.UseItInteractively] = "Use It Interactively";
tr[Keys.ModelOcrUseServiceMessage] = "Once you select an Api Key, a modal and an OCR option, you'll be able to use the service.";
tr[Keys.UseService] = "Use Service";
tr[Keys.VerificationLinkSentMessage] = "We have sent a verification link to  new email Check your email to complete verification process You might need to check your spam folder";
tr[Keys.VerifyYourEmailMessage] = "Kaydınızı tamamlamak için lütfen gönderilen bağlantıyı tıklayarak e-postanızı doğrulayın.";
tr[Keys.VerifyYourPassword] = "Verify Your Password";
tr[Keys.VisitOur] = "Visit our";
tr[Keys.WantToMakeChanges] = "Değişiklikleri gerçekleştirmek istiyor musunuz ?";
tr[Keys.Weekly] = "Haftalık";
tr[Keys.WelcomeToArkapi] = "Welcome to ArkApi";
tr[Keys.Xml] = "XML";
tr[Keys.YesDelete] = "Evet, sil";
tr[Keys.Yes] = "Evet";
tr[Keys.YouAnnotateAtLeast20DocumentsTrainNewModel] = "You must annotate at least 20 documents to train a new model";
tr[Keys.YouCanCopyKeyMessage] = "You can copy your key and use it for your Api requests";
tr[Keys.YouCanCreateClassificationModelMessage] = "You can add a class and documents to that class to create a new classification model type.";
tr[Keys.YouCanCreateNewKeyMessage] = "You can create a new key for your account or for your organization. Each key can be customized for services.";
tr[Keys.YouCanCreateNewModelMessage] = "You can create a new model for your organization. Each model can be customized.";
tr[Keys.YouCanSetNewPassword] = "You can set a new password";
tr[Keys.YouHaveNotSpentAnyCredit] = "You haven’t spent any credit yet";
tr[Keys.YouHaventFinishedCreditPayment] = "You havent finished credit payment yet. Do you want to leave without finishing?";
tr[Keys.YourComment] = "Yorumunuz";
tr[Keys.YourPasswordHasBeenCreated] = "Şifreniz oluşturuldu";
tr[Keys.YourSelectedPlan] = "Your selected plan";
tr[Keys.YourStatisticWillBeReadySoon] = "Your statistic will be ready soon";
tr[Keys.DocumentUploaded] = "{count} Document(s) uploaded"
tr[Keys.GridView] = "Grid View"
tr[Keys.ListView] = "List View"
tr[Keys.DeleteTheClass] = "Delete the Class"
tr[Keys.DoYouWantToDeleteTheClass] = "Do you want to delete the class?"
tr[Keys.IfYouDeleteClass] = "If you delete it, your uploaded files in this classes will also be deleted."
tr[Keys.AddClassToStart] = "Add a Class to Start"
tr[Keys.YouNeedToCreateAClass] = "You need to create a class first to start uploading documents."
tr[Keys.IconAddField] = "+ Add Field";
tr[Keys.Copy] = "Kopyala";
tr[Keys.File] = "File";
tr[Keys.CountFiles] = "{count} Dosya";
tr[Keys.JsonDownloadText] = "Json";
tr[Keys.ZipDownloadText] = "Zip";
tr[Keys.SplitIntoPageRanges] = "Split into page ranges of:"
tr[Keys.SplitUseServiceMessage] = "Split PDF by custom ranges or fixed ranges into independent PDFs. You can select an API Key, upload document, select range mode and use the service."
tr[Keys.FixedRanges] = "Fixed Ranges"
tr[Keys.CustomRanges] = "Custom Ranges"
tr[Keys.SelectRangeMode] = "Select range mode:"
tr[Keys.RangeWithNumber] = "Range {number}:"
tr[Keys.FromPage] = "from page:"
tr[Keys.To] = "to:"
tr[Keys.AddRange] = "Add Range"
tr[Keys.FixedRangesSplitInfoMessage] = "This PDF will be split into files of {fixedRange} pages. {pdfCount} will be created."
tr[Keys.CustomRangesSplitInfoMessage] = "This PDF will be split into files. {pdfCount} will be created."
tr[Keys.OcrType] = "Ocr Type"
tr[Keys.NumberOfClasses] = "Number of Classes"
tr[Keys.NumberOfTotalDocuments] = "Number of Total Documents"
tr[Keys.UploadNewDocuments] = "Upload New Documents"
tr[Keys.UploadDocuments] = "Upload Documents"
tr[Keys.UploadedDocuments] = "Uploaded Documents"
tr[Keys.View] = "Görüntüle"
tr[Keys.Classes] = "Classes"
tr[Keys.ClassificationStartInfoMessage] = "Waiting for your first file to start."
tr[Keys.ClassificationResponseInfoMessage] = "You can view which classes your documents belong to"
tr[Keys.CreditsRemained] = "Credits Remained"
tr[Keys.Edited] = "Edited"
tr[Keys.CountDocumentsAreDisplayed] = "{count} documents are displayed"
tr[Keys.ChangeUploadView] = "Change Upload View"
tr[Keys.AddAClassByTyping] = "Add a class by typing here"
tr[Keys.TrainDocumentsFromScratch] = "Train documents from scratch or use classes from ready-made base models"
tr[Keys.UploadFilesToSelectedClass] = "You can upload files to selected class"
tr[Keys.UploadMoreFilesToSelectedClass] = "You can upload more files to selected class"
tr[Keys.SaveYourModelStartTraining] = "You can save your model and start training or continue adding classes and files later"
tr[Keys.UploadFiles] = "Upload Files"
tr[Keys.AddAField] = "Add a Field"
tr[Keys.AddAFieldByTyping] = "Add a field by typing here"
tr[Keys.ChooseCategory] = "Choose a Category"
tr[Keys.ChooseCategoryForField] = "Choose a category for your field"
tr[Keys.AnnotateFields] = "Annotate Fields"
tr[Keys.AnnotateFieldsOnDocument] = "Annotate fields on the document"
tr[Keys.SwitchBetweenDocuments] = "Switch Between Documents"
tr[Keys.SwitchBetweenDocumentsHere] = "You can switch between different documents here"
tr[Keys.YouCanUploadNewDocuments] = "You can upload new documents"
tr[Keys.OnceYouConfirm] = "Once you confirm your file, you can save it and go to the model page or continue annotating other files."
tr[Keys.IfYouSaveYourModel] = "If you save your model, you can start training or you can continue to annotate the documents later."
tr[Keys.EnterCorrectValue] = "Enter Correct Value"
tr[Keys.IncorrectPassword] = "Incorrect Password"
tr[Keys.UseServiceMessage] = "You can select an API Key, upload document and use the service."
tr[Keys.MergeUseServiceMessage] = "You can select an API Key, upload documents and use the service."
tr[Keys.OcrUseServiceMessage] = "Once you select an API Key and upload document , you'll be able to use the service."
tr[Keys.ApproveFileSuccessfully] = "Approve file successfully..."
tr[Keys.UploadLower] = "upload"
tr[Keys.YouCanUploadImage] = "You can upload .jpg, .png, .webp, .tiff, heic or .pdf"
tr[Keys.DragAndDropOr] = "Drag and drop or"
tr[Keys.ToStart] = "to start"
tr[Keys.FilesUploadedSuccessfully] = "Files uploaded successfully."
tr[Keys.FileUploadFailed] = "File upload failed."
tr[Keys.YourApiResponseWillBeReady] = "Your API Response will be ready for review very soon."
tr[Keys.Revoke] = "Revoke"
tr[Keys.YourApiKeysWilBeReady] = "Your API Keys will be ready soon."
tr[Keys.CopyClientId] = "Copy Client ID"
tr[Keys.IHaveReadAndApprove] = "I have read and approve";
tr[Keys.YouNeedToAccept] = "You need to accept {form}"
tr[Keys.PreliminaryInformation] = "Preliminary Information"
tr[Keys.YourModelsWillBeReady] = "Your models will be ready soon."
tr[Keys.OrderNo] = "Sipariş Numarası"
tr[Keys.SessionExpired] = "Oturum süreniz dolmuştur. Login ekranına yönlendirileceksiniz. Lütfen bekleyin."
tr[Keys.TrainStart] = "Model eğitimi başlamıştır. Eğitim tamamlanınca modeliniz 'Kullanıma Hazır' statüsüne çekilecektir."
tr[Keys.CommonDelete] = "Silme işlemi başarıyla tamamlandı"
tr[Keys.CommonSave] = "Kayıt işlemi başarıyla tamamlandı"
tr[Keys.CommonUpdate] = "Güncelleme işlemi başarıyla tamamlandı"
tr[Keys.KeyRegenerate] = "Api Key tekrar oluşturuldu"
tr[Keys.KeyRevoke] = "Api Key iptal edildi"
tr[Keys.CommonDeleteButton] = "Sil"
tr[Keys.ModelDeleteHeader] = "Model Silme"
tr[Keys.ModelDeleteBody] = "Modeli silmek istediğinize emin misiniz?"

export default tr;
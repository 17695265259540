import { toast } from "../components/toast/ToastHook";
import * as Keys from "../translations/keys";
import { getTranslatedIntl } from "./language";

export const getHandleServiceResponse = (result: any, type: string) => {
    if (result === null || result.data === null) {
        toast.error("Error!");
        return false;
    }
    //Token refresh error
    if (result && result?.data?.length === 0 && result?.errors === true)
        return false;
    if (result.data.errors !== null &&
        result.data.errors[0] !== null &&
        result.data.errors[0].items !== null &&
        result.data.errors[0].items[0] !== null) {
        toast.error(result.data.errors[0].items[0].message);

        return false;
    }
    if (result.data.isSuccessed) {
        if (type === "new") {
            toast.success(getTranslatedIntl(Keys.CommonSave));
        }
        if (type === "update") {
            toast.success(getTranslatedIntl(Keys.CommonUpdate));
        }
        if (type === "delete") {
            toast.success(getTranslatedIntl(Keys.CommonDelete));
        }
        if (type === "train") {
            toast.success(getTranslatedIntl(Keys.TrainStart));
        }
        if (type === "keyregenerate") {
            toast.success(getTranslatedIntl(Keys.KeyRegenerate));
        }
        if (type === "keyrevoke") {
            toast.success(getTranslatedIntl(Keys.KeyRevoke));
        }
    }

    return result.data.isSuccessed;
}
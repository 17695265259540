import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CurrentCreditResponse } from "../../api/contracts/cloudai.api/response/common/CurrentCreditResponse";
import { creditCustomerCreditGetCurrentCredit } from "../../api/services/cloudai.api/credit/customerCredit";
import { IconButton } from "../../components/button/IconButton";
import { ArrowRight } from "../../components/icons";
import { Typography } from "../../components/typography/Typography";
import { StateModel } from "../../models/store/StateModel";
import * as Keys from "../../translations/keys";
import { translateString } from "../../utils/language";
import { Url_Settings_Purchase } from "../../utils/routeHelper";
import styles from "./styles/apiKeyCreditBlock.module.scss";
import { ApiKeyCreditBlockProps } from "./types/ApiKeyCreditBlockProps";
import { getHandleServiceResponse } from "../../utils/serviceHelper";

export const ApiKeyCreditBlock = (props: ApiKeyCreditBlockProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const refresh = useSelector((state: StateModel) => state?.liveInterface?.refresh);

  let controller = new AbortController();

  const [credit, setCredit] = useState<number>();

  useEffect(() => {
    onGetCredit();
  }, [refresh]);

  const onGetCredit = () => {
    controller.abort();
    controller = new AbortController();

    creditCustomerCreditGetCurrentCredit(controller.signal)
      .then((response: AxiosResponse<CurrentCreditResponse>) => {
        if (getHandleServiceResponse(response, "")) {
          setCredit(response.data.currentCredit);
        }
      })
  };

  return (
    <div className={styles.creditContainer}>
      <div>
        <Typography color="white" size="3xl" weight="semibold" label={credit ? credit.toString() : "-"} />
      </div>
      <div className={styles.creditContainer__textBlock}>
        <div>
          <Typography color="white" size="sm" weight="medium" label={translateString(intl, Keys.CreditsRemained)} />
        </div>
        <div className={styles.creditContainer__textBlock__purchase}>
          <Typography color="white" size="xs" label={translateString(intl, Keys.PurchaseMoreCredits)} />
          <IconButton size="small" color="transparent" icon={<ArrowRight />} onClick={() => navigate(Url_Settings_Purchase)} />
        </div>
      </div>
    </div>
  );
};
